<template>
  <TicketsListLayout ref="layout"
                     :title="$t('ticket.myTickets.menuTitle')"
                     :index-request="indexRequest"
                     :crud-headers="crudHeaders"
                     can-create-ticket
                     detail-to="tickets.show"
  />
</template>

<script>
import TicketsListLayout from '@/modules/tickets/components/TicketsListLayout.vue';
import { mapGetters } from 'vuex';
import { storeIndex } from '@/modules/tickets/api/ticket.js';

export default {
  name: 'MyTicketsList',
  components: { TicketsListLayout },
  watch: {
    async storeId() {
      this.$refs.layout.$refs.table.reload();
    },
  },
  computed: {
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }),
    ...mapGetters('settings', { storeId: 'getCurrentStoreId' }),
    crudHeaders() {
      return [
        {
          value: 'ticketStatusId',
          language: 'status',
        },
        {
          value: 'locationType',
          language: 'location',
        },
        {
          value: 'subject',
          language: 'subject',
        }
      ];
    },
  },
  methods: {
    indexRequest(...args) {
      return storeIndex(this.clientId, this.storeId, ...args);
    },
  },
};
</script>
